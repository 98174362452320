@import "../../../../../../styles/variables";
@import "../../../../../../styles/global";
@import "../../../../../../styles/mixins";

.popupContainer {
  width: 900px;
}

.popupHeader {
  height: 20px;
}

.popupBody {
  padding: 30px;
  height: calc(100% - 40px);
  text-align: left;
}

.selectionItemsHeader {
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
  font-weight: bold;
}



.item {
  padding-left: 30px;
  text-align: left;

  &.indicator {
    width: 40px;
    text-align: center;
    padding-left: 0;
    font-size: $font-size6-5;

    cursor: pointer;
  }

  &.small {
    width: 100px;
  }

  &.medium {
    width: 160px;
  }

  &.large {
    width: 300px;
  }
}

.selectionItems {
  border: $border2;
  margin-bottom: 30px;
  max-height: 300px;
  height: 300px;
  overflow-y: auto;
}

.emptyResults {
  height: 40px;
  line-height: 40px;
}

.popupBodyHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding-bottom: 15px;
}

.title {
  display: flex;
  justify-content: flex-start;
  font-size: $font-size6;
}

.searchContainer {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.searchInputContainer {
  width: 380px;
  background-color: $color-grey0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: $border2;
}

.searchIcon {
  color: $color-grey4;
}

.searchInput {
  height: 30px;
  width: 90%;
  outline: none;
  border: none;
  background-color: $color-grey0;
  padding: 0 20px;
  font-size: $font-size6
}

.toolbar {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  height: 40px;
  align-items: center;
  margin-bottom: 5px;

  .toolbarItem {
    display: flex;
    margin-right: 20px;
  }

  .link {
    @include no-select();
    padding: 0 5px;
    color: $color-action;
    cursor: pointer;

    &.active {
      color: $color-black;
    }

    &.disabled{
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

.loading {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.button{
  &.disabled{
    pointer-events: none;
    opacity: 0.5;
  }

  &.searchButton{

  }
}
