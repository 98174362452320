@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.widgetContainer {
  @include no-select();
  display: flex;
  flex-direction: column;
  background-color: $color-white;
  border: $border2;
  border-radius: 10px;
  height: 100%;
  width: 100%;
}

.chartView {
  padding-left: 35px;
  height: 100%;
  width: 100%;
}

.infoIcon{
  color: $color-grey3;
  margin-left: 10px;
}

.tooltip {
  border-radius: 4px;
  padding: 5px 10px;
  text-align: center;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.4);
    width: 300px;
}

.titlePanel {
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 15px;
  font-size: $font-size4;
}

.subtitle {
  font-size: $font-size4;
  margin-top: 5px;
  color: $color-grey5
}

.contentPanel {
  flex: 1 1;
  display: flex;
  align-items: flex-start;
}

.graphContainer {
  width: 294px;

  .barGraph {
    position: relative;
    width: 294px;
    height: 30px;
    border: $border2;
    border-radius: 15px;
    display: flex;
  }

  .servicesSection {
    border-radius: 15px 0 0 15px;
    background-color: $color-blue5;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tiresSection {
    border-radius: 0 15px 15px 0;
    background-color: $color-yellow5;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .leftBorderRadius {
    border-radius: 15px 0 0 15px;
  }

  .rightBorderRadius {
    border-radius: 0 15px 15px 0;
  }

  .allBorderRadius {
    border-radius: 15px;
  }

  .sectionLabel {
    padding-top: 5px;
    text-align: center;
    font-size: $font-size4;
    width: 100%;
  }
}

.indicator {
  top: -10px;
  position: absolute;
  height: 40px;
  width: 3px;
  color: $color-white;
  background-color: $color-white;

  .icon {
    position: absolute;
    top: -10px;
    left: -12px;
  }
}

.infoTooltip {
  width: 260px;
}

.tooltip {
  border-radius: 4px;
  padding: 5px 10px;
  text-align: center;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.4);
}

.legend {
  margin-top: 10px;
  display: flex;
}

.tiresDot {
  height: 10px;
  width: 10px;
  background-color: $color-yellow5;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.servicesDot {
  height: 10px;
  width: 10px;
  background-color: $color-blue5;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

