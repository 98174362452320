@import "../../../styles/variables";
@import "../../../styles/mixins";

.popupContainer {
  @include no-select;
  width: 1250px;
  height: 650px;
}

.loadingContainer {
  text-align: left;
  padding: 30px;
  display: flex;

  .caption {
    margin-left: 10px;
  }
}

.popupContent {
  text-align: left;
  min-height: 100%;
}

.contentTitle {
  padding: 15px 30px;
  min-height: 80px;

  .type {
    color: $color-grey7;
    font-size: $font-size5;
    margin-bottom: 5px;
  }

  .id {
    font-size: $font-size7;
  }
}

.infoContainers {
  display: flex;
  flex-direction: row;
}

.infoContainer {
  width: 33.3%;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}

.field {
  margin-bottom: 10px;
  display: flex;

  .label {
    width: 150px;
    text-align: right;
    color: $color-grey7;
    padding-right: 10px;
  }

  .value {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .action {
    cursor: pointer;
    color: $color-blue4;

    &:hover {
      text-decoration: underline;
    }
  }
}

.contentBody {
  height: 570px;
  border-top: 1px solid $color-grey2;
  background-color: $color-grey0;
  padding: 20px 40px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow-y: scroll;
}

.configurationContainer {
  max-height: 420px;
  background-color: $color-white;
  border: $border2;
  border-radius: 10px 10px 0 10px;
  display: flex;
}

.configurationView {
  min-width: 280px;
  max-width: 280px;
  height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: $border2;
}

.configurationDetails {
  width: 100%;
}

.loading {
  padding-top: 40px;
  display: flex;
  min-width: 800px;
  justify-content: center;
}

.jobsTitle {
  padding: 20px;
  font-weight: bolder;
}
