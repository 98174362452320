@import "../../../styles/variables";
@import "../../../styles/global";
@import "../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $color-grey0;
}

.header {
  display: flex;
  justify-content: flex-end;
}

.content {
  @include center-flex-x(flex);
}

.serviceProviderGroupsContainer {
  display: flex;
  background-color: $color-white;
  border: $border2;
  border-radius: 10px;
  width: 800px;
  height: 500px;
}

.availableServiceProviderGroups {
  width: 70%;
  height: 100%;
  padding: 30px;
}

.availableTitle {
  font-size: $font-size7;
  margin-bottom: 30px;
}

.searchContainer {
  width: 100%;
  background-color: $color-grey0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: $border2;
}

.searchIcon {
  color: $color-grey4;
}

.searchInput {
  height: 40px;
  width: 90%;
  outline: none;
  border: none;
  background-color: $color-grey0;
  padding: 0 20px;
  font-size: $font-size6
}

.resultContainer {
  margin-top: 20px;
  max-height: 350px;
  overflow-y: auto;
  padding-right: 30px;
  margin-right: -30px;
}

.resultItem {
  position: relative;
  padding: 5px;
  color: $color-blue5;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  border-radius: 10px;

  &.isSelected {
    background-color: $color-grey0;
  }

  &:hover {
    text-decoration: none;
    color: $color-blue10;
  }
}

.indicator {
  position: relative;
  left: 18px;
  width: 15px;

  & + span {
    margin-left: 33px;
  }
}

.matchingCharacter {
  color: $color-blue6;
  font-weight: bold;
}

.tooManyResults {
  margin-top: 20px;
  padding-left: 53px;
  color: $color-blue5;
  font-size: $font-size4;
}

.recentServiceProviderGroups {
  width: 30%;
  height: 100%;
  padding: 30px 20px;
  background-color: $color-grey0;
  border-left: $border2;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.recentServiceProviderGroupsTitle {
  font-size: $font-size7;
  font-weight: bold;
  margin-bottom: 25px;
  margin-left: 10px;
}

.recentServiceProviderGroup {
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    border-radius: 10px;
    color: $color-blue4;

    & > .recentDate {
      color: $color-grey7;
    }
  }

  &:not(:nth-child(2)) {
    margin-top: 10px;
  }
}

.recentServiceProviderGroupNone {
  cursor: default;

  &:hover {
    background-color: $color-grey0;

    & > .recentDate {
      color: $color-grey5;
    }
  }
}

.recentName {
  font-size: $font-size6;
  margin-bottom: 5px;
}

.recentDate {
  font-size: $font-size5;
  color: $color-grey5;
}
