@import "../../../../../../styles/variables";
@import "../../../../../../styles/global";
@import "../../../../../../styles/mixins";

.fcItem {
  @include no-select();
  display: flex;
  height: 40px;
  align-items: center;
  border-bottom: $border2;
  cursor: pointer;

  &.isDisabled {
    opacity: 0.4;
    pointer-events:none;
  }

  &:hover {
    background-color: $color-grey1;

    .indicator {
      background-color: $color-grey2;
    }
  }

  .indicator {
    @include center-flex(flex);
    height: 40px;
    width: 40px;
    border-right: $border2;
    background-color: $color-grey1;
  }

  .name {
    padding-left: 30px;
  }
}
