@import "../../../styles/variables";
@import "../../../styles/global";

.dropdownPanel {
  @include no-select;
  position:relative;
  display: inline-block;
}

.selectionPanel {
  font-size: $font-size5;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15px;
  height: 40px;
  border-left: $border2;
  min-width: 125px;
  width: 100%;
  cursor: pointer;
  outline: none;
  min-width: 200px;

  .selectionIcon {
    display: inline-flex;
    padding: 0 5px 0 10px;
  }

  .selectionText {
    flex-grow: 1;
  }

  &:hover {
    background-color: $color-grey1;
  }

  &.isOpen {
    background-color: $color-grey1;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.optionsPanel {
  @include transition-animation(0.2s);
  height: 0;
  font-size: $font-size5;
  z-index: 999;
  position: absolute;
  top: 39px;
  visibility: visible;
  display: flex;
  flex-direction: column;
  width: calc(100% + 1px);
  border: none;
  overflow: hidden;

  &.isOpen {
    border: 1px solid $color-grey3;
  }
}

.optionItem {
  @include center-flex(inline-flex);
  justify-content: flex-start;
  padding-left: 15px;
  background-color: $color-white;
  cursor: pointer;
  height: 40px;

  &:hover {
    background-color: $color-grey1;
  }
}
