@import "../../../../../styles/variables";
@import "../../../../../styles/mixins";

.selectionItems {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.selections {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.serviceProviderItem {
  display: flex;
  min-height: 41px;
  border-bottom: $border2;
  cursor: pointer;

  &:hover {
    background-color: $color-grey1;
  }

  .allServiceProvidersSelector {
    @include center-flex(flex);
    width: 40px;
    background-color: $color-grey2;
  }

  .serviceProviderSelector {
    @include center-flex(flex);
    min-width: 40px;
    background-color: $color-grey1;
  }

  .itemText {
    @include text-ellipsis;
    @include no-select();
    display: inline-block;
    height: 40px;
    padding: 11px 30px;
    color: $color-grey4;
    max-width: 330px;

    &.isSelected {
      color: $color-black;
    }

    &.isPartial {
      color: $color-black;
    }

    &.name{
      min-width: 350px;
      max-width: 350px;
    }

    &.field1{
      min-width: 280px;
      max-width: 280px;
    }

    &.field2{
      min-width: 100px;
      max-width: 100px;
    }
  }
}

.selectionActions {
  display: flex;
  min-height: 41px;
  border-bottom: $border2;

  .selectAll {
    @include no-select();
    display: flex;
    height: 40px;
    align-items: center;
    flex-grow: 1;
    padding: 0 15px;
    color: $color-black;
  }

  .selectLabel {
    margin: 0 5px;
  }

  .selectAction {
    cursor: pointer;
    color: $color-grey4;

    &:hover {
      color: $color-blue4;
    }

    &.active {
      color: $color-black;
    }
  }
}

.searchContainer {
  width: 300px;
  margin: 5px 0 5px 5px;
  background-color: $color-grey0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: $border2;
}

.searchIcon {
  color: $color-grey4;
}

.searchInput {
  height: 27px;
  width: 80%;
  outline: none;
  border: none;
  background-color: $color-grey0;
  padding: 0 20px;
  font-size: $font-size6
}
