@import "../../../styles/variables";
@import "../../../styles/global";

.dropdownPanel {
  position: relative;
  display: inline-block;
}

.selectionPanel {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 15px;
  height: 40px;
  border: 1px solid $color-grey2;
  min-width: 150px;
  cursor: pointer;
  outline: none;
  border-radius: 10px;
  background-color: $color-grey0;

  .selectionIcon {

  }

  .selectionText {
    @include text-ellipsis();
    margin-right: 10px;
    width: 100%;
  }

  &.isOpen {
    background-color: $color-blue4;
    color: $color-white;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.isDisabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.optionsPanel {
  position: absolute;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid $color-grey2;
  border-top: none;
  max-height: 250px;
  overflow: auto;
  z-index: 100;

  &.isOpen {
    visibility: visible;
  }
}

.optionItem {
  @include text-ellipsis();
  padding-left: 15px;
  background-color: $color-white;
  cursor: pointer;
  min-height: 40px;
  line-height: 40px;
  max-width: 100%;
  &:hover {
    background-color: $color-grey1;
  }
}
