@import "../../../styles/variables";
@import "../../../styles/global";

.table {
  height: 100%;
}

.rows {
  height: calc(100% - 40px);
  overflow-y: auto;
}

.row {
  display: flex;
  height: 40px;
  border-bottom: $border2;

  &.header {
    font-weight: bolder;
  }

  &.noLastRowBorder{
    &:last-child {
      border-bottom: none;
    }
  }
}

.cell {
  display: inline-block;
  margin: auto 0;
  padding: 0 25px;
  width: 240px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cellLink {
  color: $color-blue5;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}
