@import "../../../../styles/variables";
@import "../../../../styles/global";
@import "../../../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: $color-grey0;
}

.content {
  padding: 0 30px;
}


.tabs {
  display: flex;
}

.tab {
  padding: 10px 30px;
  background-color: white;
  border: $border2;
  border-bottom: none;
  font-size: $font-size5;

  &:first-of-type {
    border-right: none;
    border-top-left-radius: 10px;
  }

  &:last-of-type {
    border-top-right-radius: 10px;
  }

  &.active {
    background-color: $color-grey1;
  }

  &:hover {
    cursor: pointer;
    background-color: $color-grey1;
  }
}

.subTitle {
  font-size: $font-size6;
  padding-bottom: 10px;

  &:not(:first-of-type) {
    padding-top: 20px;
  }
}

.sectionTitle{
  font-size: $font-size5;
  padding-top: 10px;
  padding-bottom: 10px;
}

.contentPanel {
  background-color: white;
  border: $border2;
  border-radius: 0 10px 10px 10px;
  max-width: 1400px;
  padding: 30px;
  overflow-y: auto;
  max-height: calc(100vh - 150px);

  &.isDisabled{
    .vField {
      .value {
        opacity: 0.7;
        pointer-events: none;
      }
    }

    .hField {
      .value {
        opacity: 0.7;
        pointer-events: none;
      }
    }

    .checkBoxItem{
      opacity: 0.7;
      pointer-events: none;
    }
  }
}

.email {
  font-size: $font-size8;
}

.fields {
}

.vFieldGroup {
  display: flex;
  margin: 10px 0;
}

.vField {
  padding-bottom: 10px;
  margin-right: 30px;
  width: 320px;

  &.unlimited{
    width: unset;
  }

  .label {
    padding-bottom: 10px;
    color: $color-grey5;
  }

  .value {
    display: flex;
    align-items: center;
  }

  .control{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
}

.hField {
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  .label {
    padding-right: 10px;
    color: $color-grey5;
  }

  .value {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
}

.fieldInput {
  background-color: $color-grey0;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  max-width: 360px;
  outline: 0;
  border: $border2;
  font-size: $font-size5;
  padding: 15px 15px;

  &.small {
    max-width: 150px;
    padding: 8px 10px;
  }
}

.select {
  &.spaced {
    margin-left: 20px;
  }
}

.unit {
  margin-left: 10px;
  color: $color-grey3;
}

.availableIndicator {
  margin-left: 20px;
  color: $color-grey3;
}

.domains {
  @include center-flex-x(flex);
  flex-direction: column;
  width: 80%;
}

.addDomain {
  font-size: $font-size5;
  text-align: left;
  color: $color-blue4;
  cursor: pointer;
  margin-left: 5px;
}

.buttons {
  display: flex;
  margin-top: 60px;
}

.button {
  margin-right: 20px;

  &.isDisabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

.spacer {
  height: 20px;
}

.loading {
  padding: 40px;
}

.checkBoxItem {
  height: 35px;
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;

  .checkBox {
    @include center-flex(flex);
    padding-right: 15px;
    font-size: $font-size6;
  }

  .checkBoxText {
    @include no-select();
    padding: 11px 0;
    color: $color-black;
  }

  &.disabled{
    pointer-events: none;
    opacity: 0.5;
  }
}


.row {
  height: 50px;
  display: flex;
  border-radius: 5px;
  border-bottom: 1px solid $color-grey1;
  align-items: center;
  padding: 0 20px;

  &:hover {
    background-color: $color-grey1;
  }

  &.header {
    font-weight: bolder;
    height: 40px;
    background-color: $color-white;
  }

  &.isDisabled{
    opacity: 0.7;
    pointer-events: none;
  }
}

.cell {
  flex: 1 1;
}
