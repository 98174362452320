@import "../../../../../styles/variables";
@import "../../../../../styles/global";
@import "../../../../../styles/mixins";

.pagingContainer {
  @include no-select();
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  margin-bottom: 20px;

  &.disabled {
    .changePage {
      opacity: 0.5;
      pointer-events: none;
    }

    .pageNumber {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.pageNumberContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pageNumber {
  min-width: 25px;
  padding: 0 5px;
  display: flex;
  flex-grow: 1;
  cursor: pointer;
  justify-content: center;

  &:hover {
    color: $color-blue5;
  }
}

.placeholderNumber {
  width: 30px;
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.pageNumberActive {
  color: $color-blue5;
}

.changePage {
  cursor: pointer;

  &:hover {
    color: $color-blue5;
  }
}

.rightPanel {
  display: flex;
}

.itemsPerPage {
  padding-right: 10px;
}
